.box {
  display: flex;
  width: 100%;
  height: 400px;
  margin: 220px 0;
  align-items: center;
  justify-content: center;
  background: #d8e8d6;
  position: relative;
  overflow: hidden;
  font-family: "Roboto" sans-serif;
}

.link {
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  background: black;
  width: fit-content;
  padding: 20px;
  border-radius: 32px;
  overflow: hidden;
}

.link svg {
  transform: translate(0%, -5%);
}

.left {
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 50%;
}
.left h2 {
  font-size: 2rem;
  font-weight: 800;
  width: 60%;
}
.left h2 span {
  color: #46614f;
}
.left p {
  font-size: 1.2rem;
  font-weight: 400;
}
.image1 {
  min-height: 300px;
  min-width: 285px;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  right: 230px;
  z-index: 10;
}

.image2 {
  max-height: 400px;
  min-height: 400px;
  min-width: 285px;
  max-width: 285px;
  object-fit: cover;
  position: absolute;
  right: 0;
  z-index: 5;
}
.image3 {
  max-height: 400px;
  min-height: 400px;
  min-width: 285px;
  max-width: 285px;
  object-fit: cover;
  position: absolute;
  right: 100px;
  z-index: 5;
}
.right {
  display: flex;
  width: 30%;
  position: relative;
  height: 100%;
  padding: 0;
}
.design1 {
  position: absolute;
  min-height: 100%;
  top: 100px;
  right: 0;
  z-index: 1;
}
.design2 {
  position: absolute;
  min-height: 100%;
  top: 20px;
  right: 0;
  z-index: 1;
}
@media screen and (max-width: 968px) {
  .box {
    display: none;
  }
}
@media (max-width: 1440px) {
  .left {
    width: 50%;
  }
  .right {
    width: 40%;
  }
  .design1 {
    width: 50%;
  }
  .design2 {
    width: 40%;
  }
  .left p {
    width: 50%;
  }
}
