/* Styles for the entire showcase section */
.showcase {
  background-color: var(--clr-white);
  padding: 130px 0;
}

/* Styles for the container which holds the text contents and the buttons */
.content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 90%;
  margin: 0 auto;
  align-items: center;
  text-align: left;
}

/* Styles for the small showcase image to show in mobiles */
.imageSmall {
  width: 80%;
  height: auto;
  padding: 32px;
  clip-path: inset(48% 0 0 0);
  margin-top: calc(-62% + 32px);
}

/* Styles for the big showcase image to show in big screens */
.imageBig {
  display: none;
}

/* Styles for the image which shows the rating of the app */
.ratingImage {
  display: none;
}

/* Styles for the big heading */
.title {
  text-align: left;
  font-size: 2rem;
}

/* Styles for the container which holds the cta text and the link container */
.cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.cta p {
  font-weight: 600;
}

/* Styles for the link to download the APK file */
.apk {
  color: var(--clr-gray3);
  font-size: 0.875rem;
}

/* Styles for the container which holds the two links (or buttons) */
.links {
  display: flex;
  flex-direction: column;
  width: 60%;
  align-items: center;
}

/* Styles for the svg inside the link */
.links svg {
  width: 100%;
  min-width: 120px;
}

/* Responsive styles for large mobile screen */
@media screen and (min-width: 425px) {
  .links {
    flex-direction: row;
    width: 90%;
    justify-content: space-between;
    gap: 16px;
  }

  .title {
    font-size: 2.2rem;
  }
}

/* Responsive styles for tablet screen */
@media screen and (min-width: 768px) {
  .showcase {
    background-color: inherit;
    display: flex;
    width: 80%;
    margin: 0 auto;
    align-items: center;
    padding: 0;
    gap: 32px;
  }

  .content {
    width: auto;
    align-items: flex-start;
    margin: 0;
  }

  .imageSmall {
    display: none;
  }

  .imageBig {
    display: inline;
    width: 32%;
    height: auto;
  }

  .cta {
    align-items: flex-start;
  }
}

/* Responsive styles for laptop screen */
@media screen and (min-width: 1024px) {
  .showcase {
    width: 70%;
    gap: 80px;
  }

  .content {
    gap: 40px;
  }

  .links {
    width: 60%;
  }
}

/* Responsive styles for large laptop screen */
@media screen and (min-width: 1440px) {
  .ratingImage {
    display: inline;
    width: 40%;
    height: auto;
  }

  .showcase {
    width: 60%;
  }
}

/* Responsive styles for larger screen */
@media screen and (min-width: 1920px) {
  .showcase {
    width: 50%;
  }
}
