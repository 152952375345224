/* Styles for the whole FAQ section including each item and the title */
.faqsSection {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 32px;
  width: 100%;
  min-height: 700px;
  margin: 100px 0;
  position: relative;
  overflow-y: hidden;
}

.faqsSection h2 {
  font-size: 2.5rem;
  width: fit-content;
  font-weight: 800;
}
.faq {
  list-style: none;
}
.summaryText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.faq[open] {
  max-height: 500px;
  transition: max-height 1s forward;
}
.faq > summary {
  list-style: none;
  cursor: pointer;
}

.faq > summary::-webkit-details-marker {
  display: none;
}

.detail {
  overflow: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease-in;
  -moz-transition: all 0.4s ease-in;
  -o-transition: all 0.4s ease-in;
}

/* Styles for the container which holds all FAQ items */
.faqs {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px 40px;
  border-radius: 16px;
  z-index: 10;
  box-shadow: 0px 0px 200px rgba(0, 0, 0, 0.05);
}

/* Styles for each of the FAQ accordion */
.faq {
  background: var(--clr-white);
  border-bottom: 1px solid var(--opq-gray3-20);
  display: flex;
  flex-direction: column;
  padding-inline: 32px;
  padding-top: 24px;
  margin: 16px 0;
  max-width: 40vw;
  min-width: 40vw;
  cursor: pointer;
  user-select: none;
}

/* Styles for the summary part of each FAQ */
.summary {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 1.2rem;
}

/* Styles for the custom plus icon in the FAQ item */
.plus {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.horizontal {
  position: absolute;
  left: 0px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.wave {
  position: absolute;
  top: -50px;
  left: 0;
  width: 100%;
}

/* Styles for the content inside the accordion (The content which reveals when it is opened) */
.detail {
  color: var(--opq-black-80);
  line-height: 1.8;
  font-size: 1rem;
}
.lastFaq {
  border: none;
}
.divider {
  height: 20px;
}

@media (max-width: 768px) {
  .faqsSection {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  .faqsSection h2 {
    font-size: 16px;
  }
  .detail {
    font-size: 14px;
  }

  .faqs {
    padding: 20px 0px;
    width: 85%;
  }

  .summary {
    font-size: 0.9rem;
    transition: font-size 0.3s ease;
    gap: 20%;
    align-items: first baseline;
  }

  .faq {
    max-width: 100%;
    min-width: 100%;
    padding: 10px 16px;
    padding-bottom: 5px;
  }
  .wave {
    display: none;
  }
}
