/* Styles for the container which holds the links container and the header */
.linksSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  width: 90%;
  padding: 50px 50px;
  height: 400px;
  background: #d8e8d6;
  margin: 200px auto;
  position: relative;
  border-radius: 16px;
}

/* Styles for the container of title and subtitle */
.titles {
  font-size: 2rem;
  font-weight: 800;
}
.titles h2 span {
  color: #46614f;
}

/* Styles for the container which holds all the links */
.links {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: auto auto;
  gap: 16px;
  width: 100%;
  justify-content: center;
}

/* Styles for each othe links */
.link {
  display: flex;
  flex-direction: column;
  background-color: var(--clr-white);
  border: 1px solid var(--opq-gray3-20);
  text-decoration: none;
  padding: 16px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  gap: 8px;
  transition: 200ms;
  width: 150px;
  z-index: 10;
}
.design3 {
  position: absolute;
  top: 0;
  left: 0%;
}
.design1 {
  position: absolute;
  bottom: 0;
  right: 0;
}
.design2 {
  position: absolute;
  bottom: 0;
  right: 0;
}

/* Styles to apply when hovering a link */
.link:hover {
  box-shadow: 4px 4px 4px var(--opq-black-10);
}

/* Styles for the link title. ie the language name in the language itself */
.linkTitle {
  color: var(--clr-black);
  font-size: 1rem;
}

/* Styles for the link language. ie the language name in English */
.linkLang {
  color: var(--opq-black-80);
  font-size: 0.875rem;
}

/* Responsive styles for tablet screen */
@media screen and (min-width: 720px) {
  .linksSection {
    width: 80%;
    align-items: center;
    padding: 50px 40px;
    height: fit-content;
    min-height: 400px;
  }
  .links {
    display: flex;
    flex-wrap: wrap;
    width: 100% !important;
    padding: 0;
  }
  .link {
    width: 150px;
  }
}
@media screen and (max-width: 1000px) {
  .links {
    display: flex;
    flex-wrap: wrap;
    width: 100% !important;
    padding: 0;
  }
}
/* Responsive styles for laptop screen */
@media screen and (min-width: 1024px) {
  .linksSection {
    gap: 40px;
    width: 70%;
    height: fit-content;
    min-height: 400px;
  }
}

/* Responsive styles for large screen */
@media screen and (min-width: 1440px) {
  .linksSection {
    padding: 30px 50px;
  }

  .link {
    width: 150px;
  }
}

/* Responsive styles for larger screen */
@media screen and (min-width: 1920px) {
  .linksSection {
    width: 70%;
  }
}

@media screen and (max-width: 720px) {
  .linksSection {
    width: 100vw;
    padding: 32px 20px;
    margin: 0;
    height: fit-content;
    align-items: center;
    border-radius: 0;
  }
  .link a {
    font-size: 0.8rem;
  }
  .titles {
    width: 100%;
    text-align: center;
  }
  .design3 {
    display: none;
  }
}
