.card {
  background-color: var(--clr-gray2);
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  width: 350px;
  height: 300px;
  grid-column: 2.5 / 3.5; /* Item 4 will be in the second column */
  justify-self: center;
}
.featureTitle {
  font-size: 1.1rem;
  margin-bottom: 0.4rem;
  font-weight: 700;
}
.featureText {
  background: white;
  padding: 1rem;
  height: 50%;
  border-radius: 0px 0px 16px 16px;
  border: #e5e5e5 solid 1px;
  line-height: 25px;
  font-size: 20px;
}
.featureText p {
  font-size: 1rem;
  font-weight: 400;
  color: #888888;
}
.featureArt {
  width: 100%;
  height: 50%;
  border-radius: 16px 16px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  user-select: none;
}
.featureArtHolder {
  width: 80%;
  height: 80%;
  border-radius: 16px 16px 0px 0px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.darkDisabled {
  background-color: white;
  color: black;
  border: #e5e5e5 solid 1px;
  border-radius: 5px;
  display: flex;
  margin-top: 1rem;
  font-size: 0.8rem;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  padding-block: 0.3rem;
  padding-inline: 1rem;
  width: 90%;
}
.darkText {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.darkDisabledSwitch {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.1rem;
  padding-inline: 0.3rem;
  gap: 0.5rem;
  background: #979899;
  width: 50px;
  height: 30px;
  border-radius: 15px;
}
.darkEnabledSwitch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.1rem;
  padding-inline: 0.3rem;
  gap: 0.5rem;
  background: #58c473;
  width: 50px;
  height: 30px;
  border-radius: 15px;
}
.darkSwitchCircle {
  background: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
}
.darkEnabled {
  background-color: #212121;
  color: white;
  border: #e5e5e5 solid 1px;
  border-radius: 5px;
  display: flex;
  margin-top: 0.5rem;
  font-size: 0.8rem;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  padding-block: 0.3rem;
  padding-inline: 1rem;
  width: 90%;
}
