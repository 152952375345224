/* Styles for the container which holds both the cards and the total heading */
.features {
  display: flex;
  flex-direction: column;
  padding: 128px 0 8px 0;
  gap: 32px;
  width: 90%;
  margin: 100px auto;
  font-family: "Roboto", sans-serif;
}
.features h1 {
  font-size: 40px;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
}

/* Styles for the container which holds all the feature cards */
.cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Styles for each of the feature card */
.card {
  background-color: var(--clr-white);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 20px;
}

/* Styles for the container which holds the card icon and heading */
.featureTitle {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Responsive styles for tablet screen */
@media screen and (max-width: 768px) {
  .features {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .features {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 128px 0 8px 0;
    gap: 32px;
    width: 90%;
    margin: 100px auto;
    font-family: "Roboto", sans-serif;
  }
  .features h1 {
    font-size: 2.8rem;
    font-family: "Roboto", sans-serif;
    font-weight: 900;
  }
  .cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    gap: 20px;
  }
  .cardsOdia {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    place-items: center;
    gap: 20px;
  }
  .card {
    flex: 1;
  }
  .featureTitle {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
  .featureIcon {
    width: 90%;
    height: 90%;
  }
}
/* Responsive styles for laptop screen */
@media screen and (min-width: 1090px) {
  .features {
    display: flex;
    gap: 40px;
    width: 70%;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    gap: 20px;
  }
  .cardsOdia {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    place-items: center;
    gap: 20px;
  }
  .card {
    flex: 1;
  }

  .featureTitle {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  /* Styles for the icon in feature title */
  .featureIcon {
    width: 90%;
    height: 90%;
  }
}
