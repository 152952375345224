/* Styles for the base footer */
.footer {
  background-color: var(--clr-gray4);
}

/* Styles for the container inside footer */
.container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  gap: 24px;
}

/* Styles for the container which holds the two links in footer */
.links {
  display: flex;
  gap: 16px;
}

/* Styles for each of the links in footer */
.link {
  text-decoration: none;
  color: var(--clr-white);
}

/* Responsive styles for tablet screen */
@media screen and (min-width: 768px) {
  .container {
    width: 80%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 32px 0;
  }
}

/* Responsive styles for laptop screen */
@media screen and (min-width: 1024px) {
  .container {
    width: 70%;
    padding: 40px 0;
  }
}
