.card {
  background-color: var(--clr-gray2);
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  width: 350px;
  height: 300px;
}
.featureTitle {
  font-size: 1.1rem;
  margin-bottom: 0.4rem;
  font-weight: 700;
}
.featureText {
  background: white;
  padding: 1rem;
  height: 50%;
  border-radius: 0px 0px 16px 16px;
  border: #e5e5e5 solid 1px;
  line-height: 25px;
  font-size: 20px;
}
.featureText p {
  font-size: 1rem;
  font-weight: 400;
  color: #888888;
}
.featureArt {
  width: 100%;
  height: 50%;
  border-radius: 16px 16px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  user-select: none;
}
.featureArtHolder {
  width: 80%;
  height: 80%;
  border-radius: 16px 16px 0px 0px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.voiceTyping {
  border: #e5e5e5 solid 1px;
  border-radius: 50px;
  display: flex;
  margin-top: 1rem;
  margin-left: 1rem;
  font-size: 0.8rem;
  align-self: flex-start;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding-block: 0.5rem;
  padding-inline: 1rem;
}

.listening {
  color: #2a8451;
  display: flex;
  gap: 0.5rem;
  padding-block: 0.5rem;
  padding-inline: 1rem;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  border: #e5e5e5 solid 1px;
  border-radius: 50px;
  margin-top: 1rem;
  margin-right: 1rem;
  font-weight: 700;
  font-size: 0.8rem;
}
