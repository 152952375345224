.card {
  background-color: var(--clr-gray2);
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  width: 350px;
  height: 300px;
  grid-column: 1.5 / 2.5; /* Item 4 will be in the second column */

  justify-self: center;
  align-self: center;
}
.featureTitle {
  font-size: 1.1rem;
  margin-bottom: 0.4rem;
  font-weight: 700;
}
.featureText {
  background: white;
  padding: 1rem;
  height: 50%;
  border-radius: 0px 0px 16px 16px;
  border: #e5e5e5 solid 1px;
  line-height: 25px;
  font-size: 20px;
}
.featureText p {
  font-size: 1rem;
  font-weight: 400;
  color: #888888;
}
.featureArt {
  width: 100%;
  height: 50%;
  border-radius: 16px 16px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  user-select: none;
}
.featureArtHolder {
  width: 80%;
  height: 80%;
  border-radius: 16px 16px 0px 0px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.image1 {
  width: 70%;
  position: absolute;
  left: 5%;
  top: 10%;
}
.image2 {
  width: 70%;
  position: absolute;
  right: 5%;
  top: 50%;
}
.toolImg {
  width: 100%;
  height: 70%;
  position: relative;
}
