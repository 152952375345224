.card {
  background-color: var(--clr-gray2);
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  width: 350px;
  height: 300px;
}
.featureTitle {
  font-size: 1.1rem;
  margin-bottom: 0.4rem;
  font-weight: 700;
}
.featureText {
  background: white;
  padding: 1rem;
  height: 50%;
  border-radius: 0px 0px 16px 16px;
  border: #e5e5e5 solid 1px;
  line-height: 25px;
  font-size: 20px;
}
.featureText p {
  font-size: 1rem;
  font-weight: 400;
  color: #888888;
}
.featureArt {
  width: 100%;
  height: 50%;
  border-radius: 16px 16px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  user-select: none;
}
.featureArtHolder {
  width: 80%;
  height: 80%;
  border-radius: 16px 16px 0px 0px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sideBar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.1rem;
  padding-top: 1rem;
  background-color: white;
  width: 50%;
  font-size: 0.6rem;
  border-radius: 8px;
  height: 100%;
  border-right: #e5e5e5 solid 0.1px;
}
.logoHolder {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-inline: 1rem;
}
.logo {
  width: 20px;
  height: 20px;
  border-radius: 20px;
}
.newFile {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-block: 1.2rem;
  padding-inline: 1rem;
}
.draft1 {
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: #d8e8d6;
  padding: 0.5rem 1rem;
  border-radius: 3px;
}
.time {
  color: #888888;
  margin-left: 1rem;
}
